/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { FormSilverpopRequiredFields, FormTypes } from '@powdr/constants';

export const DiscountForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
  passthroughFunc,
}) => {
  const captchaRef = useRef();

  return (
    <form
      id="pa-discount-form"
      onSubmit={(e) => processForm(
        e,
        null,
        null,
        captchaRef,
        FormTypes.SILVERPOP,
      )}
    >

      <h2>$150 off full week summer camp</h2>
      <p>Sign up for news and offers from Woodward and get a $150 discount code for full-week summer camps for 2025!</p>

      <input name="CONTACT_LIST_ID" type="hidden" value="16908569" />

      <input
        className="form-control"
        type="text"
        name="Name"
        id="control_COLUMN3"
        label="First and Last Name"
        placeholder="First and Last Name"
      />

      <input
        className="form-control"
        type="text"
        name={FormSilverpopRequiredFields.EMAIL}
        id="control_EMAIL"
        label="Email Address"
        placeholder="Email Address"
      />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <div className="flex-row" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            passthroughFunc();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          value="Submit"
          disabled={!isUserVerified}
        >
          {buttonText || 'GET MY CODE'}
        </button>
      </div>
    </form>
  );
};

DiscountForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  passthroughFunc: PropTypes.func.isRequired,
};

DiscountForm.defaultProps = {
  buttonText: null,
};
